import React from "react";

import { GravitywellTheme } from "src/config/theme";
import DeathMarch from "src/assets/images/impact/death-march.jpg";
import Viewpoint from "src/assets/images/impact/viewpoint.jpg";
import TreePlantingHero from "src/assets/images/impact/tree-planting-hero.jpeg";
import TreePlanting from "src/assets/images/impact/tree-planting.jpg";
import PhotoStack from "src/assets/images/impact/photo-stack.jpg";
import DayOut from "src/assets/images/impact/day-out.jpg";
import Merch from "src/assets/images/impact/merch.jpg";
import { PageTopCustom } from "src/components/home-hero/home-hero";

import styled from "../theme/styled";
import {
  FullWidthColumnWrapper,
  SmallColumnWidthWrapper
} from "../components/confines-wrappers";
import Footer from "../components/footer";
import Header from "../components/header";
import HelmetWrapper from "../components/helmet-wrapper";
import {
  breadCrumbSchema,
  localBusinessSchema,
  organizationSchema
} from "../components/html-schema-snippets";
import Layout from "../components/layout";
import PageTop from "../components/page-top";
import StayInTheLoop from "../components/stay-in-the-loop";

const TextWithImageRight = styled.div<{color?: string}>`
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div {
    width: 60%;
  }

  .image {
    width: 40%;
  }

  .caption {
    padding: ${({ theme }) => theme.sizes.spacing.xl};
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    div {
      width: 100%;
    }

    .image {
      width: 100%;
    }

    .caption {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const TextWithImageLeft = styled.div<{color?: string}>`
  background-color: ${({ color }) => color};
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: ${({ theme }) => theme.sizes.spacing.xxl};
  padding-bottom: ${({ theme }) => theme.sizes.spacing.xxl};

  div {
    width: 60%;
  }

  .image {
    width: 40%;
  }

  .caption {
    padding: ${({ theme }) => theme.sizes.spacing.xl};
  }

  @media (max-width: 768px) {
    flex-direction: column;

    div {
      width: 100%;
    }

    .image {
      width: 100%;
    }

    .caption {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const Impact = () => {
  const breadcrumbsSchemaData = breadCrumbSchema([
    {
      name: "Impact",
      path: "/impact"
    }
  ]);

  const schemaJson = [
    localBusinessSchema,
    organizationSchema,
    breadcrumbsSchemaData
  ];

  return (
    <Layout>
      <>
        <HelmetWrapper
          canonicalPath="/impact"
          title="Impact"
          description="Making a difference"
          updatedAt={Date.now().toString()}
        >
          <script type="application/ld+json">
            {JSON.stringify(schemaJson)}
          </script>
        </HelmetWrapper>

        <header role="banner">
          <Header
            initialLogo
            initialTheme="dark"
          />

          <PageTopCustom id="hero">
            <PageTop
              style={{
                paddingTop: 0,
                paddingBottom: 0
              }}
              height="medium"
              backgroundImage={TreePlantingHero}
            >
              <FullWidthColumnWrapper
                className="top-content"
              >
                <p
                  className="heading"
                >
                  Impact & Sustainability
                </p>
              </FullWidthColumnWrapper>
            </PageTop>
          </PageTopCustom>
        </header>

        <main
          id="main-content"
          role="main"
        >
          <h1 className="off-screen">
            Impact & Sustainability
          </h1>

          <SmallColumnWidthWrapper
            style={{
              marginTop: GravitywellTheme.sizes.spacing.xxl,
              marginBottom: GravitywellTheme.sizes.spacing.xxl
            }}
          >
            <h2 style={{ fontSize: GravitywellTheme.font.size.display_4 }}>
              Our Vision
            </h2>

            <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
              Some businesses are created to have a specific social or environmental impact. They exist to do good in the world - so it’s easy to see how and why they make a difference.
            </p>

            <p
              style={{ fontSize: GravitywellTheme.font.size.body_2 }}
            >
              But what about all the other businesses, who don’t exist exclusively for social good but who still think it matters? How can we run ethical, sustainable businesses in
              {" "}

              <b>
                any
              </b>

              {" "}
              industry?
            </p>

            <p
              style={{
                fontSize: GravitywellTheme.font.size.body_2,
                color: GravitywellTheme.color.brandColor,
                fontWeight: "bold"
              }}
            >
              That’s where Gravitywell comes in.
            </p>

            <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
              We aim to support
              {" "}

              <b>
                all
              </b>

              {" "}
              tech businesses - no matter their industry - to grow sustainably and keep impact at the heart of success.
            </p>
          </SmallColumnWidthWrapper>

          <div
            className="image"
            style={{ minWidth: "50%" }}
          >
            <img
              className="full"
              src={Viewpoint}
              alt="George and Sam admiring the view"
            />
          </div>

          <SmallColumnWidthWrapper
            style={{ marginTop: "10rem" }}
          >
            <h2
              style={{ fontSize: GravitywellTheme.font.size.display_3 }}
            >
              Purpose
            </h2>

            <p
              style={{ fontSize: GravitywellTheme.font.size.body_2 }}
            >
              We build tech startups.
            </p>

            <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
              And that could be all that we do. But Gravitywell is made up of people who care about protecting the planet, making an impact and giving back to our local communities.
            </p>

            <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
              We're surfers, hikers, plant-lovers and more  - so we want our work to reflect what matters to us.
            </p>
          </SmallColumnWidthWrapper>

          <FullWidthColumnWrapper
            style={{ textAlign: "center" }}
            className="content-container"
          >
            <TextWithImageLeft>
              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.pexels.com/photos/274025/pexels-photo-274025.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt="A member of the Gravitywell team giving direction"
                />
              </div>

              <div
                className="caption"
              >
                <h4>
                  Green business for
                  {" "}

                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    all
                  </span>
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  At Gravitywell, we believe that every business deserves the opportunity to build, scale and innovate
                  {" "}

                  <i>
                    ethically and sustainably
                  </i>

                  {" "}
                  - from day one. By integrating this into every step of a startup’s journey, we take action early and build businesses with strong foundations.
                </p>
              </div>
            </TextWithImageLeft>

            <TextWithImageRight>

              <div
                className="caption"
              >
                <h4>
                  Scale
                  {" "}

                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    consciously
                  </span>
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  Growth and profit
                  {" "}

                  <i>
                    can
                  </i>

                  {" "}
                  be the measures of a good business - but so are impact, team happiness, work-life-balance, innovation and customer loyalty. We encourage our clients to consider success through a wider lens and to pursue the version that suits them - even if it doesn’t fit the traditional expectations.
                </p>
              </div>

              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.pexels.com/photos/355863/pexels-photo-355863.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt="Members of the Gravitywell team admiring the view from a balcony"
                />
              </div>
            </TextWithImageRight>
          </FullWidthColumnWrapper>

          <div
            className="image"
          >
            <img
              className="full"
              src={PhotoStack}
              alt="A number of members of the Gravitywell team taking photos of each other"
            />
          </div>

          <SmallColumnWidthWrapper
            style={{
              marginTop: "10rem",
              marginBottom: GravitywellTheme.sizes.spacing.xxl
            }}
          >
            <h2
              style={{ fontSize: GravitywellTheme.font.size.display_3 }}
            >
              People
            </h2>

            <p
              style={{ fontSize: GravitywellTheme.font.size.body_2 }}
            >
              We don't do bureaucracy.
            </p>

            <p
              style={{ fontSize: GravitywellTheme.font.size.body_2 }}
            >
              Instead, we do our best to work in the same ways that we live the rest of our lives - having fun, sticking to our morals and giving back where we can.
            </p>

            <p
              style={{ fontSize: GravitywellTheme.font.size.body_2 }}
            >
              We're all individuals, and we encourage everyone to ask questions and bring their own perspective to the job. After all, it's our differences that make it interesting to work together!
            </p>

            <p
              style={{ fontSize: GravitywellTheme.font.size.body_2 }}
            >
              From camping trips and hackathons in Spain to after-work beers and summer parties, we're a friendly bunch - but we also recognise the opportunities we have to do good.
            </p>
          </SmallColumnWidthWrapper>

          <FullWidthColumnWrapper
            style={{ textAlign: "center" }}
            className="content-container"
          >

            <TextWithImageLeft>
              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.pexels.com/photos/5942025/pexels-photo-5942025.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt="A number of members of the Gravitywell team taking photos of each other"
                />
              </div>

              <div
                className="caption"
              >
                <h4>
                  Build
                  {" "}

                  <span
                    style={{ color: GravitywellTheme.color.brandColor }}
                  >
                    humane
                  </span>

                  {" "}
                  technology
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  Some technology is designed to steal our attention and sell our data - without considering the impact that has on us as people. We aim to build businesses and platforms which solve problems for their communities, and which acknowledge and lessen any negative side-effects. A number of our team members have completed the Center for Humane Technology’s Foundations of Humane Technology course, and we keep what’s best for our users at the forefront of our minds in everything we do.
                </p>
              </div>
            </TextWithImageLeft>

            <TextWithImageRight>
              <div
                className="caption"
              >
                <h4>
                  Keep a
                  {" "}

                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    balance
                  </span>
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  Whilst our jobs are important to us, we all have other things in our lives that sometimes can - and should - take priority. From flexibility for the school run to afternoons spent in nature, we do our best to talk openly about the pressures of our industry and to keep work balanced with everything else we’re up to.
                </p>
              </div>

              <div
                className="image"
              >
                <img
                  className="full"
                  src={DeathMarch}
                  alt="The team on a walk"
                />
              </div>
            </TextWithImageRight>

            <TextWithImageLeft>
              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.unsplash.com/photo-1471958680802-1345a694ba6d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80"
                  alt="Members of the Gravitywell planting trees"
                />
              </div>

              <div className="caption">
                <h4>
                  Hold ourselves
                  {" "}

                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    accountable
                  </span>
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  Going through the BCorp process has allowed us to pat ourselves on the back for some really good things - and focussed our attention on what more we can do. We see ethics and sustainability as an incremental journey where enough small, maintainable steps can make a huge difference. That’s why for us, BCorp is a starting point - not a goal - and we’re always looking for opportunities to question and improve the ways we work and do business.
                </p>
              </div>
            </TextWithImageLeft>

            <TextWithImageRight>
              <div className="caption">
                <h4>
                  Give
                  {" "}

                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    back
                  </span>
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  We offer our time, knowledge and physical ability to good causes in our local area, from free talks and workshops to pro-bono work. We recently spent a morning planting trees with One Tree Per Child, a Bristol re-wilding initiative.
                </p>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  We dedicate time to help charities and social good initiatives without the resources to pay for development time. Recently this has included helping with pitch decks for an environmental charity, and working with the Epilepsy Society on a project to lessen the risks of social media for those who suffer with epilepsy.
                </p>
              </div>

              <div
                className="image"
              >
                <img
                  className="full"
                  src={TreePlanting}
                  alt="Members of the Gravitywell planting trees"
                />
              </div>
            </TextWithImageRight>

          </FullWidthColumnWrapper>

          <div
            className="image"
          >
            <img
              className="full"
              src={DayOut}
              alt="The team posing on a walk in front of Clifton Suspension Bridge"
            />
          </div>

          <SmallColumnWidthWrapper
            style={{
              marginTop: "10rem",
              marginBottom: GravitywellTheme.sizes.spacing.xxl
            }}
          >
            <h2
              style={{ fontSize: GravitywellTheme.font.size.display_3 }}
            >
              Planet
            </h2>

            <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
              Whereas we can see the plastic packaging in landfill or sewage on our beaches, it can be hard to visualise the impact of the technology we buy, use or build.
            </p>

            <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
              But data centres - physical buildings where data is stored and processed - are expected to contribute 3.2% of the worldwide carbon emissions by 2025. And electronics contain rare earth materials which are energy-intensive to source and near-impossible to recycle. Buying, using and disposing of technology has more of an impact than it might seem.
            </p>

            <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
              One of the more insidious things about the impact of technology is that it’s not clear who’s responsible for it. The manufacturer who designs phones to break, or the phone network that sells an upgrade every year? The companies who own the data centres, the businesses that pay to use them, or even the users who cause the internet traffic?
            </p>

            <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
              It’s a tough question to answer, but the simplest answer is: all of them. In our own way, we all have some part to play in improving the environmental impact of technology, whether it’s by being more conscious consumers, writing more efficient software, or by pushing suppliers to make greener tech a priority.
            </p>

            <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
              As a company who use, design and build technology, we have a lot of opportunities to think about our ways of working and how they affect the environment. Below are a number of the things we do to reduce our impact - both for us and our clients.
            </p>

            <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
              We’ve taken some prompts from these
              {" "}

              <a
                href="https://principles.green/"
                style={{
                  textDecoration: "underline",
                  color: GravitywellTheme.color.brandColor
                }}
              >
                Principles of Green Software Engineering
              </a>
              .
            </p>
          </SmallColumnWidthWrapper>

          <FullWidthColumnWrapper
            style={{ textAlign: "center" }}
            className="content-container"
          >
            <TextWithImageLeft>
              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.pexels.com/photos/269318/pexels-photo-269318.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt="Member of the Gravitywell team working on laptops"
                />
              </div>

              <div className="caption">
                <h4>
                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    Minimise
                  </span>

                  {" "}
                  energy use
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  We aim for our software to use the minimum energy possible, since more energy is linked to higher environmental impact. We try to take responsibility of this side effect, by writing efficient code with the minimum computation necessary.
                </p>
              </div>
            </TextWithImageLeft>

            <TextWithImageRight>

              <div
                className="caption"
              >
                <h4>
                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    Maximise
                  </span>

                  {" "}
                  use of renewable energy
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  Our energy grid is made up of a combination of renewable energy sources - wind, solar and geothermal energy for instance - and more harmful sources like oil and gas. Renewable energy sources can’t be scaled on demand, so if we need more energy at peak times it has to be generated from the more damaging sources.
                </p>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  At night, there is a surplus of renewable energy (such as wind power), so we make the most of this by running software background tasks during these times of lower demand - also helping to relieve pressure at peak times.
                </p>
              </div>

              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.unsplash.com/photo-1467533003447-e295ff1b0435?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                  alt="Members of the Gravitywell team at work"
                />
              </div>
            </TextWithImageRight>

            <TextWithImageLeft>
              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.pexels.com/photos/1181275/pexels-photo-1181275.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt="Member of the Gravitywell team working on laptops"
                />
              </div>

              <div className="caption">
                <h4>
                  Use hardware
                  {" "}

                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    efficiently
                  </span>
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  We think carefully about the hardware we use, and minimise the initial embodied carbon by tailoring what we buy to its intended use.
                </p>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  For instance, our software engineers may need higher performance machines than management or marketing teams - so we account for that by choosing the right spec for the job.
                </p>
              </div>
            </TextWithImageLeft>

            <TextWithImageRight>

              <div
                className="caption"
              >
                <h4>
                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    Look after
                  </span>

                  {" "}
                  it
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  We try to keep our hardware in good condition and extend its life by looking after it properly. We restart laptops regularly, keep them up-to-date, charge them efficiently and use energy-saving features like screen brightness adjustments.
                </p>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  Software engineers are careful to avoid running local development servers overnight, which can put unnecessary pressure on the machines.
                </p>
              </div>

              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.pexels.com/photos/5208870/pexels-photo-5208870.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt="Members of the Gravitywell team at work"
                />
              </div>
            </TextWithImageRight>

            <TextWithImageLeft>
              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.pexels.com/photos/3850467/pexels-photo-3850467.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt="Member of the Gravitywell team working on laptops"
                />
              </div>

              <div className="caption">
                <h4>
                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    Reuse
                  </span>

                  {" "}
                  it
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  We don’t throw hardware away unless it’s broken and can’t be fixed. We always first check if a spare piece of hardware can be used in the team - for instance by handing older high-spec development machines to non-technical staff who won’t use them so heavily. If there’s no use for the hardware for us, we always look to re-sell or donate to a trusted third party, so the technology can benefit someone else. And if something really does have to go, we make sure to dispose of electronic waste safely.
                </p>
              </div>
            </TextWithImageLeft>

            <TextWithImageRight>

              <div
                className="caption"
              >
                <h4>
                  Only use what we
                  {" "}

                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    need
                  </span>
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  Most servers are always on, meaning that they sit idle until they’re needed for a task. This is convenient but not energy efficient, as idle resources still consume energy and have a huge environmental impact over time. To avoid this, we use serverless technology - which means that we only use server computational power when we need it, and free it up for other people to use when we don’t need it. This has a significant positive impact as it reduces our idle time to near-zero, whilst also reducing the number of servers needed in total by sharing them between companies and software projects.
                </p>
              </div>

              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.unsplash.com/photo-1544197150-b99a580bb7a8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                  alt="Members of the Gravitywell team at work"
                />
              </div>
            </TextWithImageRight>

            <TextWithImageLeft>
              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.pexels.com/photos/210182/pexels-photo-210182.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt="Member of the Gravitywell team working on laptops"
                />
              </div>

              <div className="caption">
                <h4>
                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    Maximise
                  </span>

                  {" "}
                  hardware utilisation
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  We auto-scale our hardware to maximise utilisation of any single resource, improving energy efficiency. When we need to implement auto-scaling we think carefully about the thresholds at which we should be making use of an extra server, and split the traffic equally to balance utilisation across all servers and keep efficiency as high as possible.
                </p>
              </div>
            </TextWithImageLeft>

            <TextWithImageRight>

              <div
                className="caption"
              >
                <h4>
                  Keep data
                  {" "}

                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    nearby
                  </span>
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  We choose data centres that are as close to our end users as possible - generally London where possible, and Ireland as a backup. On projects with the majority of users based outside of the UK, we make appropriate adjustments to keep data transfers short-range.
                </p>
              </div>

              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.pexels.com/photos/95719/pexels-photo-95719.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt="Members of the Gravitywell team at work"
                />
              </div>
            </TextWithImageRight>

            <TextWithImageLeft>
              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://images.pexels.com/photos/7203788/pexels-photo-7203788.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  alt="Member of the Gravitywell team working on laptops"
                />
              </div>

              <div className="caption">
                <h4>
                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    Minimise
                  </span>

                  {" "}
                  requests & data
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  A lot of the work we do relies on building APIs, which are a bit like real-life libraries - they store and provide access to information. Because retrieving and delivering this data uses energy, we design APIs carefully so that they provide the minimum data necessary at any time - like finding someone the chapter they need, rather than the entire book.
                </p>
              </div>
            </TextWithImageLeft>

            <TextWithImageRight>

              <div
                className="caption"
              >
                <h4>
                  Work somewhere that
                  {" "}

                  <span style={{ color: GravitywellTheme.color.brandColor }}>
                    cares
                  </span>
                </h4>

                <p style={{ fontSize: GravitywellTheme.font.size.body_2 }}>
                  Our office is in a co-working space called
                  {" "}

                  <a
                    href="https://www.impactworking.co.uk/"
                    style={{ textDecoration: "underline" }}
                  >
                    Impact Working
                  </a>
                  , which has strong social and environmental goals and is aiming to become a BCorp. We’ve helped transitions to sustainable office supplies, reduced plastic waste and better recycling - and we’ll keep making suggestions!
                </p>
              </div>

              <div
                className="image"
              >
                <img
                  className="full"
                  src="https://hubble.imgix.net/listings/uploads/spaces/8064/_DSC4181.jpg"
                  alt="Members of the Gravitywell team at work"
                />
              </div>
            </TextWithImageRight>
          </FullWidthColumnWrapper>

          <div
            className="image"
            style={{ minWidth: "50%" }}
          >
            <img
              className="full"
              src={Merch}
              alt="The Gravitywell team on a walk in the sun"
            />
          </div>

          <aside>
            <StayInTheLoop />
          </aside>
        </main>

        <footer role="contentinfo">
          <Footer />
        </footer>
      </>
    </Layout>
  );
};

export default Impact;
